<template>
    <v-navigation-drawer
      :value="isAddUpdateSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
      app
      :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
      @input="val => $emit('update:is-add-update-sidebar-active', val)"
    >
      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">
              {{ formIcon }}
            </v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">
            {{ formTitle }}
          </div>
        </div>
        <v-btn icon x-small @click="$emit('update:is-add-update-sidebar-active', false)">
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>
  
      <form>
        <validation-observer ref="observer">
         <!--  <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Kategori Tipi" rules="required">
            <v-select
              v-model="form.ust"
              filled
              dark
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              :items="kategoriler"
              :error-messages="errors"
              class="rounded-0"
              label="Kategori Tipi"
          /></validation-provider> -->
          <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Kategori Adı" rules="required">
            <v-text-field
              v-model="form.kategori"
              filled
              :dark="isDark"
              :error-messages="errors"
              label="Kategori Adı"
              hide-details
              class="rounded-0"
            />
          </validation-provider>
          <v-text-field
            v-model="form.sira"
            filled
            :dark="isDark"
            label="Kategori Sırası"
            hide-details
            class="rounded-0"
          />
        </validation-observer>
      </form>
      <template v-slot:append>
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-btn block text @click="$emit('update:is-add-update-sidebar-active', false)" rounded>Vazgeç</v-btn></v-col
            >
            <v-col cols="6">
              <v-btn x-large rounded block :loading="loading" color="secondary" @click="submit">
                {{ formButtonText }}
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </template>
    </v-navigation-drawer>
  </template>
  
  <script>
  import store from '@/store'
  import useAppConfig from '@core/@app-config/useAppConfig'
  import {
    mdiClose,
    mdiCloseBox,
    mdiMinusBox,
    mdiCheckboxBlankOutline,
    mdiEye,
    mdiEyeOff,
    mdiFileTree,
    mdiPencil,
    mdiPlusThick,
  } from '@mdi/js'
  import { onMounted, ref } from '@vue/composition-api'
  import Vue from 'vue'
  
  export default {
    model: {
      prop: 'isAddUpdateSidebarActive',
      event: 'update:is-add-update-sidebar-active',
    },
    props: {
      isAddUpdateSidebarActive: {
        type: Boolean,
        required: true,
      },
      productType: {
        type: String,
        required: true,
      },
      productItem: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const { isDark } = useAppConfig()
  
      return {
        isDark,
  
        icons: {
          mdiClose,
          mdiEyeOff,
          mdiEye,
          mdiPlusThick,
          mdiPencil,
          mdiFileTree,
          mdiCloseBox,
          mdiMinusBox,
          mdiCheckboxBlankOutline,
        },
      }
    },
    data: () => ({
      items: [],
      isLoading: false,
      show: false,
      loading: false,
      form: {
        aktif: 1,
        ust: null,
        sira: null,
        kategori: '',
      },
      defaultForm: {
        aktif: 1,
        ust: null,
        sira: null,
        kategori: '',
      },
      kategoriler: [
        { text: 'Mamül', value: '1' },
        { text: 'Sarf', value: '2' },
      ],
    }),
  
    computed: {
      formTitle() {
        return this.productType === 'add' ? 'Yeni  Ekle' : 'Düzenle'
      },
      formIcon() {
        return this.productType === 'add' ? this.icons.mdiPlusThick : this.icons.mdiPencil
      },
      formButtonText() {
        return this.productType === 'add' ? 'Kaydet' : 'Güncelle'
      },
    },
  
    watch: {
      isAddUpdateSidebarActive(val) {
        if (val && this.productType === 'edit') {
          this.$nextTick(() => {
            this.form = {
              ust: this.productItem.ust,
              sira: this.productItem.sira,
              kategori: this.productItem.kategori,
            }
          })
        }
        if (!val) {
          this.close()
        }
      },
    },
  
    methods: {
      async submit() {
        this.loading = true
        const isValid = await this.$refs.observer.validate()
        if (!isValid) {
          this.loading = false
  
          Vue.swal({
            title: 'Hata',
            text: 'Lütfen kırımızı alanları doldurun',
            icon: 'error',
            timer: 2000,
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
  
          return
        }
  
        const data = this.form
  
        const { id } = this.productItem
  
        const search = ['kategori']
        const method = 'saveCategory'
        this.$store
          .dispatch('addUpdateMethod', {
            data,
            search,
            method,
            id,
          })
          .then(response => {
            if (response.error) {
              Vue.swal({
                title: 'Hata',
                text: response.msg,
                icon: 'error',
                timer: 2000,
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            } else {
              this.$emit('refetch-data')
              this.$emit('update:is-add-update-sidebar-active', false)
  
              if (!this.productItem.id) {
                this.$emit('upload-image', { id: response.response.insertId })
              }
              this.$emit('save-product-event', {
                message: `Veri Girişi Başarılı`,
                color: 'success',
                timeout: 5000,
              })
              this.loading = false
            }
          })
          .catch(e => {
            Vue.swal({
              title: 'Hata',
              text: e.message,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
            this.$emit('update:is-add-update-sidebar-active', false)
  
            this.loading = false
          })
      },
      close() {
        this.$refs.observer.reset()
        this.$nextTick(() => {
          this.form = { ...this.defaultForm }
        })
      },
    },
  }
  </script>
  
  <style lang="scss">
  .multiSelect {
    .v-select__selections {
      padding-bottom: 15px !important;
      padding-top: 35px !important;
    }
  }
  </style>
  